'use strict';
import {
  Alert,
  Box,
  Divider,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';

import ReactMarkdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom';
import remarkDirective from 'remark-directive';
import remarkGfm from 'remark-gfm';
import remarkToc from 'remark-toc';
import Heading from './Heading';
import HelpAlert from './HelpAlert';
import YouTubeEmbed from './YouTubeEmbed';
import htmlDirectives from './htmlDirectives';

// remark plugin to add a custom tag to the AST

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      minHeight="70vh"
      width={1}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      p={2}
      {...other}
    >
      {children}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    flex: '0 0 200px',
  },
}));

const sections = [
  { tab: 'intro', label: 'Introdução' },
  { tab: 'clientes', label: 'Clientes' },
  { tab: 'cobrancas', label: 'Cobranças' },
  { tab: 'acompanhamento', label: 'Acompanhamento' },
  { tab: 'pendencias', label: 'Pendências' },
  { tab: 'relatorio', label: 'Relatório' },
  { tab: 'configuracoes', label: 'Configurações' },
  { tab: 'cancelamento', label: 'Cancelamento' },
  { tab: 'alteracoes', label: 'Alterações' },
  { tab: 'pontual', label: 'Pagamento Pontual' },
  { tab: 'marcadores', label: 'Marcadores' },
];

export default function Help() {
  const classes = useStyles();
  const { section = 'intro' } = useParams();
  const navigate = useNavigate();

  const [currentMd, setCurrentMd] = useState();

  import(`./${section}.md`)
    .then((res) => {
      fetch(res.default)
        .then((res) => res.text())
        .then(setCurrentMd)
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));

  return (
    <Box padding={2}>
      <Box padding={1}>
        <Typography variant="h4" id="ajuda">
          Ajuda
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="row">
        <Tabs
          orientation="vertical"
          value={section}
          onChange={(_, newValue) => {
            navigate(`/help/${newValue}`);
          }}
          className={classes.tabs}
        >
          {sections.map(({ tab, label }, index) => (
            <Tab
              key={index}
              value={tab}
              label={
                <Typography variant="body2" color="textPrimary">
                  {label}
                </Typography>
              }
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <Stack direction="column" paddingX={2}>
          <Box flexGrow={1}>
            <ReactMarkdown
              remarkPlugins={[
                remarkGfm,
                remarkToc,
                remarkDirective,
                htmlDirectives,
              ]}
              components={{
                a: YouTubeEmbed,
                img: (props) => <img {...props} style={{ maxWidth: '100%' }} />,
                h1: Heading,
                h2: Heading,
                h3: Heading,
                h4: Heading,
                h5: Heading,
                h6: Heading,
                alert: HelpAlert,
              }}
            >
              {currentMd}
            </ReactMarkdown>
          </Box>
          <Box paddingBottom={10} paddingTop={5}>
            <Alert severity="info" variant="outlined">
              Caso esteja com algum problema, envie um e-mail para{' '}
              <Link href="mailto:suporte@zetaone.com.br">
                suporte@zetaone.com.br
              </Link>
            </Alert>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
